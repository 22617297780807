/**
 * Подписка на события метадаты и установка свойств
 *
 * Created by Evgeniy Malyarov on 14.02.2021.
 */

import {lazy} from './lazy';
import {load_ram} from 'metadata/react/dist/common/proxy';


export const init_state = {
  meta_loaded: false,
  common_loaded: false,
  doc_ram_loaded: false,
  complete_loaded: false,
  fetch: false,
  idle: false,
  page: {},
  offline: false,
  title: 'Редактор изделия',
  error: null,
  user: {
    logged_in: false,
    stop_log_in: false,
    try_log_in: false,
    log_error: '',
  },
  snack: null,
  alert: null,
  confirm: null,
  wnd_portal: null,
};

function sse({data}) {
  try {
    const message = JSON.parse(data);
  }
  catch (e) {}
}

function log_in({username, password}) {
  const {classes: {PouchDB}, adapters: {pouch}, wsql, cat: {users, branches}, pricing} = $p;
  const opts = {};
  if(username && password) {
    opts.headers = new Headers(Object.assign({Accept: 'application/json'},
      PouchDB.prototype.getBasicAuthHeaders({username, password})));
  }
  return fetch('/auth/couchdb', opts)
    .then((res) => res.json())
    .then((res) => {
      const {props, remote, fetch} = pouch;
      props._auth = {username: res.id};
      props._suffix = res.suffix || '';
      props._user = res.ref;

      if(typeof res.branch === 'object') {
        branches.create(res.branch,  false, true);
        res.branch = res.branch.ref;
        wsql.set_user_param('zone', res.zone);

        sessionStorage.setItem('zone', res.zone);       // number
        sessionStorage.setItem('branch', res.branch);   // guid
        sessionStorage.setItem('impersonation', '');    // guid
        sessionStorage.setItem('year', '');             // number
      }
      else {
        sessionStorage.setItem('branch', '');           // guid
        sessionStorage.setItem('impersonation', '');    // guid
        sessionStorage.setItem('year', '');             // number
      }

      const opts = {skip_setup: true, owner: pouch, fetch};
      if(username && password) {
        opts.auth = {username, password};
      }
      remote.ram = new PouchDB(pouch.dbpath('ram'), opts);
      remote.doc = new PouchDB(pouch.dbpath('doc'), opts);
      pouch.emit('user_log_in');
      wsql.evt_src.addEventListener('sse', sse, false);
      wsql.evt_src.addEventListener('nom_price', ({data}) => {
        try {
          pricing.cache.clear(JSON.parse(data));
        }
        catch (e) {}
      }, false);

      return users.create(res, false, true);
    });
}

export function actions(elm) {

  // скрипт инициализации структуры метаданных и модификаторы
  return Promise.resolve()
    .then(() => import('../../metadata'))
    .then((module) => module.init(elm))
    .then(() => {
      // font-awesome, roboto и стили metadata подгрузим асинхронно
      import('metadata/react/dist/styles/roboto/font.css');
      import('font-awesome/css/font-awesome.min.css')
        .then(() => {
          import('../../styles/global.css');
          import('../../styles/windowbuilder.css')
            .then(() => import('wb-cutting'))
            .then((module) => {
              $p.classes.Cutting = module.default;
            });
        });
    })
    .then(() => {
      const {adapters: {pouch}, job_prm, md, ui, cat} = $p;
      elm.setState({common_loaded: true});
      const {handleNavigate, handleIfaceState} = elm;
      ui.dialogs.init({handleIfaceState, handleNavigate, lazy});

      pouch.on({
        pouch_complete_loaded() {
          elm.setState({complete_loaded: true});
        },
        pouch_data_page(page) {
          elm.setState({page});
        },
        log_in({username, password}) {
          if(job_prm.autologin === false) {
            log_in({username, password})
              .then((user) => {
                elm.setState({user: {
                    name: username,
                    logged_in: true,
                    try_log_in: false,
                    log_error: '',
                  }});
                return load_ram($p);
              });
          }
        }
      });

      md.once('predefined_elmnts_inited', () => {
        // шаблоны грузим в озу сразу
        const {templates_nested} = job_prm.builder;
        let res = Promise.resolve();
        if(templates_nested && templates_nested.length) {
          for (const tmp of templates_nested) {
            res = res.then(() => tmp.load_templates());
          }
        }
        res.then(() => {
          // после загрузки данных, уточняем метаданные компоновки
          cat.scheme_settings.tune_meta();
          pouch.emit('pouch_complete_loaded');
        });
      });

      if(job_prm.autologin !== false) {
        elm.setState({user: {
            name: '',
            logged_in: false,
            try_log_in: true,
            log_error: '',
          }});

        return log_in({})
          .then((user) => {
            elm.setState({user: {
                name: user.name || user.id,
                logged_in: true,
                try_log_in: false,
                log_error: '',
              }});
            return load_ram($p);
          });
      }

    });
}
