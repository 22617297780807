import {createTheme} from '@material-ui/core/styles';
//import blue from '@material-ui/core/colors/blue';

const theme = createTheme({
  palette: {
    primary: {
      main: `#44b0ff`,
      contrastText: `#fff`
    },
    type: 'light',
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    }
  },
  typography: {
    useNextVariants: true,
    fontSize: 12.2,
  },
  overrides: {
    // MuiToolbar: {
    //   root: {
    //     minHeight: 48,
    //   },
    // },
    MuiTypography: {
      h6: {
        lineHeight: 'unset',
      }
    },
    MuiIconButton: {
      root: {
        padding: 8,
        marginRight: 4,
      },
      sizeSmall: {
        padding: 4,
      }
    },
    MuiTab: {
      root: {
        '@media (min-width:600px)': {
          minWidth: 120,
        },
        maxWidth: 'unset',
      }
    }
  },
  blueBorder: {
    border: `1px solid #44b0ff`
  },
  templateImage: {
    width: 300
  }
});

export default theme;

