/**
 * Невизуальный компонент, инициализирует метадату
 * и транслирует события адаптера в props потомков
 *
 * Created by Evgeniy Malyarov on 14.02.2021.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {createBrowserHistory} from 'history';
import {ThemeProvider} from '@material-ui/styles';  // провайдер тема material=ui
import Snack from 'metadata/react/dist/App/Snack';       // сообщения в верхней части страницы (например, обновить после первого запуска)
import Alert from 'metadata/react/dist/App/Alert';       // диалог сообщения пользователю
import Confirm from 'metadata/react/dist/App/Confirm';   // диалог вопросов пользователю (да, нет)
import WindowPortal from 'metadata/react/dist/App/WindowPortal';   // контент в новом окне (например, для печати)
import Loading from './Loading';
import {actions, init_state} from './actions';      // события метадаты
import theme from './muiTheme';                     // тема material=ui
import {lazy} from './lazy';                        // конструкторы для контекста

const history = createBrowserHistory();

export const handleNavigate = (url) => {
  history.push(url);
};

class Metadata extends React.Component {

  constructor() {
    super();
    this.state = init_state;
  }

  handleIfaceState = ({component = '', name, value}) => {
    if(!component) {
      this.setState({[name]: value});
    }
  };

  handleNavigate(url) {
    handleNavigate(url);
  }

  handleDialogClose = (name) => {
    this.handleIfaceState({name, value: {open: false}});
  };

  componentDidMount() {
    // инициализируем MetaEngine
    actions(this);
  }


  render() {
    const {props: {App}, state, handleIfaceState, handleDialogClose} = this;
    const {snack, alert, confirm, wnd_portal, ...othes} = state;
    return <ThemeProvider theme={theme}>
      {snack && snack.open && <Snack snack={snack} handleClose={handleDialogClose.bind(this, 'snack')}/>}
      {alert && alert.open && <Alert {...alert}/>}
      {confirm && confirm.open && <Confirm {...confirm}/>}
      {wnd_portal && wnd_portal.open && <WindowPortal {...wnd_portal}/>}
      {othes.meta_loaded && othes.common_loaded && othes.complete_loaded ?
        <App handleIfaceState={handleIfaceState} handleNavigate={handleNavigate} {...othes}/>
        :
        <Loading handleIfaceState={handleIfaceState} handleNavigate={handleNavigate} {...othes} />
      }
    </ThemeProvider>;
  }

  getChildContext() {
    const {handleIfaceState} = this;
    return {components: lazy, history, handleIfaceState, handleNavigate};
  }
}

Metadata.childContextTypes = {
  components: PropTypes.object,
  history: PropTypes.object,
  handleIfaceState: PropTypes.func,
  handleNavigate: PropTypes.func,
};

Metadata.propTypes = {
  App: PropTypes.elementType.isRequired,
};

function handleMessage(event) {
  if(event.source === this.opener) {
    const {action, order, ref} = event.data;
    switch (action) {
      case 'new':
        handleNavigate(`/templates?order=${order}&ref=${ref}`);
        break;
      case 'edit':
        handleNavigate(`/builder/${ref}?order=${order}`);
        break;
      default:
        console.log(event);
    }
  }
}

window.addEventListener("message", handleMessage, false);
window.addEventListener("beforeunload", () => {
  if(window.opener) {
    window.open('', window.opener.name);
    window.opener.focus();
    window.opener.postMessage({action: 'focus'});
  }
}, false);

export default Metadata;

